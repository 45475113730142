<template>
  <div
    id="app"
    class="app"
    :class="
      infoColumn == 1
        ? 'column1'
        : infoColumn == 2
        ? 'column2'
        : infoColumn == 3
        ? 'column3'
        : ''
    "
  >
    <el-card v-loading="loading">
      <div class="userInfo">
        <div class="app_title">基本资料</div>

        <div class="userInfo_content">
          <el-descriptions class="margin-top" :column="infoColumn" border>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/name.png" alt="" />
                  <span>姓名</span>
                </div>
              </template>
              <span style="font-size: 15px; font-weight: 700">
                {{ userInfo.username }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/phone.png" alt="" />
                  <span>手机号</span>
                </div>
              </template>
              <span style="font-size: 15px; font-weight: 700">
                {{ userInfo.phone }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/wechat.png" alt="" />
                  <span>微信号</span>
                </div>
              </template>
              <el-tag
                type="primary"
                style="font-size: 14px"
                v-if="userInfo.wechat != null"
              >
                {{ userInfo.wechat }}</el-tag
              >
              <el-tag
                type="danger"
                style="font-size: 14px"
                v-if="userInfo.wechat == null"
                >未绑定</el-tag
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/jiuzhen.png" alt="" />
                  <span>累计就诊次数</span>
                </div>
              </template>
              <span style="font-size: 14px; font-weight: 700">
                {{ userInfo.visit_count }}次</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/xiaofei.png" alt="" />
                  <span>累计消费</span>
                </div>
              </template>
              <span style="font-size: 14px; font-weight: 700">
                {{ userInfo.sum_pay }}元</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/chuxu.png" alt="" />
                  <span>储蓄金额</span>
                </div>
              </template>
              <span style="font-size: 14px; font-weight: 700">
                {{ userInfo.prestore }}元</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/quan.png" alt="" />
                  <span>抵券金额</span>
                </div>
              </template>
              <span style="font-size: 14px; font-weight: 700">
                {{ userInfo.ticket }}次</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/quan.png" alt="" />
                  <span>累计积分</span>
                </div>
              </template>
              <span style="font-size: 14px; font-weight: 700">
                {{ userInfo.points }}分</span
              >
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="userInfo_content_item_label">
                  <img src="@/assets/address.png" alt="" />
                  <span>住址:</span>
                </div>
              </template>
              <span v-if="userInfo.address != null" style="font-size: 14px">
                {{ userInfo.address }}</span
              >
              <el-tag
                type="danger"
                style="font-size: 16px"
                v-if="userInfo.address == null"
                >未填写</el-tag
              >
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="table">
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-date"></i> 就诊记录</span>
            <el-table
              :data="jiuzhenRecord"
              stripe
              v-loading="jiuzhenloading"
              style="width: 100%"
              :header-cell-style="{ background: '#F5FAf8' }"
            >
              <el-table-column label="就诊时间" align="center">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.diagnosisTimeStamp) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="doctorName"
                label="就诊医生"
                align="center"
              >
              </el-table-column>
              <el-table-column align="center" label="症状诊断">
                <template slot-scope="scope">
                  <span style="color: #409eff">{{
                    scope.row.diagnoseTitle
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span
                    style="color: #409eff; cursor: pointer"
                    @click="showJiuzhen(scope.row)"
                    >详情</span
                  >
                  <!-- <a style="color: #409eff" :href="scope.row.link">备注</a> -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"
              ><i class="el-icon-notebook-1"></i> 健管历史</span
            >
            <el-table
              :data="jianguanhistory"
              stripe
              v-loading="historyloading"
              style="width: 100%"
              :header-cell-style="{ background: '#F5FAf8' }"
            >
              <el-table-column label="保健时间" align="center">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.TestTime) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="ModuleName"
                label="保健项目"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="DoctorName"
                align="center"
                label="医生"
              ></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span
                    style="color: #409eff; cursor: pointer"
                    @click="showHistory(scope.row)"
                    >详情</span
                  >
                  <!-- <a style="color: #409eff" :href="scope.row.link">备注</a> -->
                </template>
              </el-table-column>
            </el-table></el-tab-pane
          >
          <el-tab-pane>
            <span slot="label"><i class="el-icon-s-custom"></i> 用户权益</span>
            <div class="uls" v-loading="quanyiLoading">
              <el-card
                class="uls_li"
                :class="
                  infoColumn == 1
                    ? 'column1'
                    : infoColumn == 2
                    ? 'column2'
                    : infoColumn == 3
                    ? 'column3'
                    : ''
                "
                v-for="(item, index) in quanyiList"
                :key="index"
              >
                <div class="li_number">{{ item.leftCount }}次</div>
                <div class="li_name">{{ item.name }}</div>
              </el-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>

    <el-dialog
      title="详情"
      :visible.sync="jiuzhenFlag"
      width="80%"
      align="center"
      :before-close="closejiuzhen"
    >
      <!-- <div class="detail_item">
        <div class="detail_item_label">就 诊 门 店</div>
        <div class="detail_item_value"></div>
      </div> -->
      <div class="detail_item">
        <div class="detail_item_label">主 诉</div>
        <div class="detail_item_value">
          {{ activejiuzhen.mainReport }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">现 病 史</div>
        <div class="detail_item_value">
          {{ activejiuzhen.medicalHistory }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">体 格 检 查</div>
        <div class="detail_item_value">
          {{ activejiuzhen.bodyCheck }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">治 疗</div>
        <div class="detail_item_value">
          {{ activejiuzhen.healthAnswer }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">辅 助 检 査</div>
        <div class="detail_item_value">
          {{ activejiuzhen.imageInfo == null ? "" : activejiuzhen.imageInfo }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">注 意 事 项</div>
        <div class="detail_item_value">
          {{ activejiuzhen.checkInfo }}
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">医嘱</div>
        <div class="detail_item_value">
          <el-table
            :data="yizhuList"
            stripe
            v-loading="yizhuLoading"
            style="width: 100%"
            :header-cell-style="{ background: '#F5FAf8' }"
          >
            <el-table-column
              prop="title"
              label="检查名称/药名称"
            ></el-table-column>
            <el-table-column prop="count" label="数量"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">检查记录</div>
        <div class="detail_item_value">
          <el-table
            :data="jianchaList"
            stripe
            v-loading="jianchaLoading"
            style="width: 100%"
            :header-cell-style="{ background: '#F5FAf8' }"
          >
            <el-table-column label="检查时间" align="center">
              <template slot-scope="scope">
                {{ formatDate(scope.row.time) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="programName"
              label="检查项"
            ></el-table-column>
            <el-table-column prop="departmentName" label="科室">
            </el-table-column>
            <el-table-column prop="doctorName" label="医生"> </el-table-column>
            <el-table-column prop="totalCost" label="检查花费">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="detail_item">
        <div class="detail_item_label">检查报告</div>
        <div class="detail_item_value">
          <el-table
            :data="reportList"
            stripe
            v-loading="reportLoading"
            style="width: 100%"
            :header-cell-style="{ background: '#F5FAf8' }"
          >
            <el-table-column prop="uploadDateStr" label="报告时间">
              <template slot-scope="scope">
                {{ formatDate(scope.row.uploadDateStr) }}
              </template></el-table-column
            >
            <el-table-column prop="title" label="报告名称"> </el-table-column>
            <el-table-column label="下载链接" align="center">
              <template slot-scope="scope">
                <a :href="scope.row.url" target="_blank">点击查看</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- <div class="detail_item">
        <div class="detail_item_label">医 嘱 待 办</div>
        <div class="detail_item_value"></div>
      </div> -->
    </el-dialog>

    <el-dialog
      title="详情"
      :visible.sync="historyFlag"
      width="80%"
      align="center"
      :before-close="closeHistory"
    >
      <div class="detail_content" v-html="activeHistory.ResultInfo"></div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      loading: true,
      historyloading: true,
      jiuzhenloading: true,
      yizhuLoading: true,
      jianchaLoading: true,
      quanyiLoading: true,
      reportLoading: true,
      userInfo: {},
      jiuzhenRecord: [],

      jianguanhistory: [],
      user_index: "",
      historyFlag: false,
      jiuzhenFlag: false,
      activeHistory: {},
      activejiuzhen: {},
      jiuzhenDetail: {},
      yizhuList: [],
      jianchaList: [],
      quanyiList: [],
      reportList: [],

      screenWidth: window.innerWidth,
      infoColumn: 4,
    };
  },
  created() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 890) {
      this.infoColumn = 4;
    } else if (this.screenWidth <= 890 && this.screenWidth > 800) {
      this.infoColumn = 3;
    } else if (this.screenWidth <= 800 && this.screenWidth > 615) {
      this.infoColumn = 2;
    } else {
      this.infoColumn = 1;
    }
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    this.user_index = queryParams.get("user_index");
    this.getUserInfo();
    this.getjiuzhen();
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth > 890) {
        this.infoColumn = 4;
      } else if (this.screenWidth <= 890 && this.screenWidth > 800) {
        this.infoColumn = 3;
      } else if (this.screenWidth <= 800 && this.screenWidth > 615) {
        this.infoColumn = 2;
      } else {
        this.infoColumn = 1;
      }
    },
    getUserInfo() {
      let data = {
        user_index: parseInt(this.user_index),
      };
      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/ODIwM2IwNmRiMTRlYzUwN2UwZjEzM2VlYjU2NTk4NGMw",
          data
        )
        .then((res) => {
          this.loading = false;
          this.userInfo = res.data[0];
        });
    },
    getjiuzhen() {
      let data = {
        user_index: parseInt(this.user_index),
      };
      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/Mzc4YzhlMjVhNWIwNGY0NGE4YmQ4NmQ4OGJhNTkzMGYw",
          data
        )
        .then((res) => {
          this.jiuzhenloading = false;
          this.jiuzhenRecord = res.data;
        });
    },
    getBaojian() {
      let data = {
        user_index: parseInt(this.user_index),
      };
      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/Mzk1YmM5OTI4MmQ5Yzc2ZjlkMmYxMzMwNzAxM2I3ZTcw",
          data
        )
        .then((res) => {
          this.historyloading = false;
          this.jianguanhistory = res.data;
        });
    },
    getQuanyi() {
      let data = {
        user_index: parseInt(this.user_index),
      };
      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/Mzk3MTI3YjE4ZjU2NjFhYWFkYWZjZWQ0OTQ1MDViYmIw",
          data
        )
        .then((res) => {
          this.quanyiLoading = false;
          this.quanyiList = res.data;
        });
    },
    handleClick(tab) {
      if (tab.getjiuzhen == 0) {
        this.getjiuzhen();
      }
      if (tab.index == 1) {
        this.getBaojian();
      }
      if (tab.index == 2) {
        this.getQuanyi();
      }
    },
    showJiuzhen(item) {
      this.activejiuzhen = item;
      this.jiuzhenFlag = true;
      let data = {
        user_index: parseInt(this.user_index),
        diagnosis_record_index: item.diagnosis_record_index,
      };
      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/YzEwM2Q1MWY3MjdiMTc0ZmM5OGQ2NDk2N2NiMjY4Mzgw",
          data
        )
        .then((res) => {
          this.yizhuLoading = false;
          this.yizhuList = res.data;
        });

      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/NDlkMTFkMTQ0ZGQzMTdhYWQ2NWJjMzFjMTU4MGU3NTAw ",
          data
        )
        .then((res) => {
          this.jianchaLoading = false;
          this.jianchaList = res.data;
        });

      axios
        .post(
          "https://open.feishu.cn/anycross/trigger/callback/ZTFlOWNmYWJkZWI5MDI1YTYzNDEwY2I4YzUwODM1Njkw",
          data
        )
        .then((res) => {
          this.reportLoading = false;
          this.reportList = res.data;
        });

      // axios
      //   .post(
      //     "https://open.feishu.cn/anycross/trigger/callback/YzMwZWNkMTBhZjE4MTIyNWM2ZTg5N2ZmNzE1OTgwYjAw",
      //     data
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     this.activejiuzhen.daiban = res.data;
      //   });
    },
    showHistory(item) {
      this.activeHistory = item;
      this.activeHistory.ResultInfo = item.ResultInfo.replace(/\\n/g, "<br/>");
      this.historyFlag = true;
    },
    closejiuzhen() {
      this.jiuzhenFlag = false;
      this.yizhuList = [];
      this.jianchaList = [];
      this.reportList = [];
      this.jianchaLoading = true;
      this.yizhuLoading = true;
      this.reportLoading = true;
    },
    closeHistory() {
      this.historyFlag = false;
    },
    formatDate(timestamp) {
      timestamp = parseInt(timestamp);
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="scss">
.app {
  overflow-x: hidden;
  padding: 40px 100px;
  &.column3 {
    padding: 40px 70px;
  }
  &.column2 {
    padding: 30px 40px;
  }
  &.column1 {
    padding: 20px;
  }
}

.app_title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
}

.userInfo_content {
  margin-top: 12px;
  .userInfo_content_ul {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-top: 20px;
  }
  .userInfo_content_item {
    display: flex;
    align-items: center;
    width: 20%;

    .userInfo_content_item_value {
      margin-left: 10px;
    }
  }
}

.userInfo_content_item_label {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: -2px;
  }
  span {
    font-size: 14px;
    color: #666;
  }
}

.table {
  margin-top: 30px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .item_label {
    color: #666;
    font-size: 14px;
  }
  .item_value {
    color: #666;
    font-size: 14px;
  }
}

.detail_item {
  width: 100%;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px dashed #ccc;
  .detail_item_label {
    width: 100px;
    text-align: justify;
    flex-shrink: 0;
    font-weight: 500;
    color: #666;
  }
  .detail_item_value {
    text-align: left;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    width: 78%;
  }
}

.uls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 200px;
  .uls_li {
    width: 30%;
    margin-bottom: 20px;
    &.column1 {
      width:100%;
      .li_number {
        font-size: 24px;
      }
      .li_name {
        font-size: 16px;
      }
    }
    &.column2{
      width: 48%;
      .li_number {
        font-size: 24px;
      }
      .li_name {
        font-size: 16px;
      }
    }
    &.column3{
      width: 48%;
      .li_number {
        font-size: 24px;
      }
      .li_name {
        font-size: 16px;
      }
    }
  }
  .li_number {
    color: #409eff;
    font-size: 40px;
    font-weight: 700;
    text-align: right;
  }
  .li_name {
    color: #666;
    text-align: right;
  }
}

.detail_content {
  line-height: 2;
  color: #666;
  font-size: 16px;
  text-align: left;
}
</style>